import React from 'react'
import { Link } from 'gatsby'
import DateBlock from '../components/DateBlock'

import '../scss/page.scss'

function SingleNewsArticleOnFrontpage(props) {
  return (
    <article className="uk-article article-frontpage">
      <p className="article-type uk-text-left uk-text-uppercase uk-text-medium article-type">
        <span>Uutinen</span>
      </p>
      <h2 className="uk-article-title uk-margin-small-top uk-margin-small-top uk-margin-small-bottom">
        <Link to={props.slug}>{props.title}</Link>
      </h2>
      <div className="uk-article-meta uk-text-medium uk-margin-small">
        <DateBlock writtenOn={props.writtenOn} modifiedOn={props.modifiedOn} />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: props.html }}
        className={'uk-text-medium body'}
      ></div>

      <Link
        className={
          'uk-button uk-button-primary uk-margin-medium-top uk-margin-small-bottom'
        }
        to={props.slug}
      >
        Siirry uutiseen <span uk-icon="arrow-right"></span>
      </Link>
      <hr />
    </article>
  )
}

export default SingleNewsArticleOnFrontpage
