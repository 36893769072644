import React from "react"
import { Link } from "gatsby"

import "./footer.scss"

function Footer(props) {
  return (
    <div id={"footer"} className="uk-margin-medium">
      <p className={"no-space"}>
        Siinä tuoreimmat viisi. Aikaisemmat uutiset, ennakot ja koosteet löydät{" "}
        <Link to={"/arkisto/"}>arkistosta</Link>.
      </p>
    </div>
  )
}

export default Footer
