import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import SingleSummaryOnFrontpage from '../components/single-summary-on-frontpage'
import SinglePreviewOnFrontpage from '../components/single-preview-on-frontpage'
import SingleNewsArticleOnFrontpage from '../components/single-news-article-on-frontpage'

import Footer from '../components/footer'

const IndexPage = ({ data }) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  const postArray = []

  // Destruct the multidimensional object array
  // Show only posts that have the publishOn date in the past

  let increment = 1
  for (let { node: post } of edges) {
    if (parseInt(post.frontmatter.timeDifferenceInMinutes) >= 0) {
      if (post.frontmatter.type === 'summary') {
        postArray.push(
          <SingleSummaryOnFrontpage
            key={increment}
            slug={post.fields.slug}
            publishOn={post.frontmatter.publishOn}
            writtenOn={post.frontmatter.writtenOn}
            modifiedOn={post.frontmatter.modifiedOn}
            title={post.frontmatter.title}
            ingress={post.frontmatter.ingress}
            date={post.frontmatter.date}
            speaker={post.frontmatter.speaker}
          ></SingleSummaryOnFrontpage>
        )
      } else if (post.frontmatter.type === 'preview') {
        postArray.push(
          <SinglePreviewOnFrontpage
            key={increment}
            slug={post.fields.slug}
            publishOn={post.frontmatter.publishOn}
            writtenOn={post.frontmatter.writtenOn}
            modifiedOn={post.frontmatter.modifiedOn}
            title={post.frontmatter.title}
            html={post.html}
            date={post.frontmatter.date}
            location={post.frontmatter.location}
            speaker={post.frontmatter.speaker}
          ></SinglePreviewOnFrontpage>
        )
      } else if (post.frontmatter.type === 'news') {
        postArray.push(
          <SingleNewsArticleOnFrontpage
            key={increment}
            slug={post.fields.slug}
            publishOn={post.frontmatter.publishOn}
            writtenOn={post.frontmatter.writtenOn}
            modifiedOn={post.frontmatter.modifiedOn}
            title={post.frontmatter.title}
            html={post.html}
            date={post.frontmatter.date}
            location={post.frontmatter.location}
          ></SingleNewsArticleOnFrontpage>
        )
      }
    }
    increment++
  }
  return (
    <Layout>
      <Seo title="Etusivu" bodyId="index" />

      <div className="posts">{postArray}</div>

      <Footer></Footer>
    </Layout>
  )
}

export default IndexPage

export const publishedPosts = graphql`
  query {
    allMarkdownRemark(
      limit: 6
      filter: { frontmatter: { publishOn: { ne: "" }, tags: { ne: "test" } } }
      sort: { fields: [frontmatter___publishOn], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            path
            date
            publishOn
            writtenOn
            modifiedOn
            ingress
            location
            year: date(formatString: "YYYY")
            type
            timeDifferenceInMinutes: publishOn(difference: "minutes")
            speaker
          }
        }
      }
    }
  }
`
