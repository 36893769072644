import React from "react"
import {Link} from "gatsby"
import DateBlock from '../components/DateBlock'
const helpers = require('../components/helpers')

function SinglePreviewOnFrontpage(props) {
  return (
    <article className="uk-article article-frontpage">
      <p className="article-type uk-text-left uk-text-uppercase uk-text-medium article-type">
        <span>Ennakko</span>
      </p>
      <h2 className="uk-article-title uk-margin-small-top uk-margin-small-top uk-margin-small-bottom">
        <Link to={props.slug}>{props.title}</Link>
      </h2>
      <div className="uk-article-meta uk-text-medium uk-margin-small">
        <DateBlock writtenOn={props.writtenOn} modifiedOn={props.modifiedOn} />
      </div>

      <dl className={'preview-description-list visible uk-text-medium'}>
        <dt className={''}>Ajankohta</dt>
        <dd>{helpers.outputDateTime(props.date)}</dd>
        <dt className={''}>Kokoontumispaikka</dt>
        <dd>{props.location}</dd>
        <dt className={''}>Alustaja(t)</dt>
        <dd>{props.speaker}</dd>
      </dl>

      <div
        dangerouslySetInnerHTML={{ __html: props.html }}
        className={'uk-text-medium body'}
      ></div>

      <Link
        className={
          'uk-button uk-button-primary uk-margin-medium-top uk-margin-small-bottom'
        }
        to={props.slug}
      >
        Siirry ennakkoon <span uk-icon="arrow-right"></span>
      </Link>
      <hr />
    </article>
  )
}

export default SinglePreviewOnFrontpage;
