import React from 'react'
import { Link } from 'gatsby'
import DateBlock from '../components/DateBlock'

import './singles-frontpage.scss'

function SingleSummaryOnFrontpage(props) {
  return (
    <article className="uk-article article-frontpage">
      <p className="article-type uk-text-left uk-text-uppercase uk-text-medium article-type">
        <span>Kooste</span>
      </p>
      <h2 className="uk-article-title uk-margin-small-top uk-margin-small-top uk-margin-small-bottom">
        <Link to={props.slug}>{props.title}</Link>
      </h2>
      <div className="uk-article-meta uk-text-medium uk-margin-small">
        <DateBlock
          writtenOn={props.writtenOn}
          modifiedOn={props.modifiedOn}
        />
      </div>

      <blockquote cite="#">
        <p class="uk-margin-small-bottom">{props.ingress}</p>
      </blockquote>

      <Link
        className={
          'uk-button uk-button-default uk-margin-medium-top uk-margin-small-bottom'
        }
        to={props.slug}
      >
        Siirry koosteeseen <span uk-icon="arrow-right"></span>
      </Link>
      <hr />
    </article>
  )
}

export default SingleSummaryOnFrontpage
